<template>
  <!-- 活动中心 -->
  <div class="activityCont">
    <div class="nav">
      <nav-bar fixed :title="order_type == 1 ? '订单记录':'充值记录'" left-text left-arrow @click-left="goBack" />
    </div>
    <div class="convert-record">
      <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList()">
        <div class="list-item" v-for="(item, index) in list_arr" :key="index">
          <div class="list-item-con">
            <div class="list-item-left">
              <div class="auth-img">
                <img :src="item.order.headimgurl ? item.order.headimgurl:auth" alt="">
              </div>
              <div class="auth-info">
                <span> {{item.order.mobile}}</span>
                <span>实付金额: {{item.order.amount}}</span>
              </div>
            </div>
            <div class="list-item-left" style="margin-right:10px">
              <div class="auth-info—2">
                <span>状态: {{ item.status == 0 ? '未结算':'已结算' }}</span>
                <span>来源: {{item.style | type }}</span>
              </div>
            </div>
          </div>
          <div class="bot-text">
            <span>购买油品: {{ item.order.name }}</span>
          </div>
          <div class="bot-text" v-if="order_type == 1">
            <span>应付金额: {{ item.order.money }}元</span>
          </div>
          
          <div class="bot-text">
            <span>支付时间: {{ item.order.payment_time | filterTime }}</span>
          </div>
          
        </div>
      </List>
    </div>
  </div>
</template>

<script>
import User from "@/api/user";
import { navBar, List } from "vant";
export default {
  components: {
    navBar,
    List,
  },
  filters: {
    // 1订单 2充值 3提现 4邀请新人 5其他
    type: function(val) {
      switch (val) {
        case 1:
          return "订单";
        case 2:
          return "充值 ";        
        case 3:
          return "提现 ";        
        case 4:
          return "邀请新人";
        case 5:
          return "其他";          
        default:
          break;
      }
    },
  },
  data() {
    return {
      page: 0,
      list_arr: [],
      loading: true,
      finished: false,
      order_type: 1,
      auth: require('../../../../../../public/static/images/vip/touxiang_1.png')
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.order_type = this.$route.query.order_type;
      if (this.order_type == 1) {
        this.getRecord(); 
      } else {
        this.getRechList();
      }
    },
    async getRecord() {
      try {
        const res = await User.getSpreadOrder(++this.page);
        if (res.data.total > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            let el = res.data.data[i];
            this.list_arr.push(el);
          }
         this.loading = false;
         if (res.data.total == this.list_arr.length) {
           this.finished = true;
         }
        } else {
          this.finished = true;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getRechList() {
      try {
        const res = await User.getSpreadRecharge(++this.page);
        console.log(res)
        if (res.data.total > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            let el = res.data.data[i];
            this.list_arr.push(el);
          }
         this.loading = false;
         if (res.data.total == this.list_arr.length) this.finished = true, this.loading = false;
        } else {
          this.finished = true;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
.convert-record {
  padding-top: 40px;
}
.activityCont {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.list-item {
  width: 96%;
  height: auto;
  border-radius: 6px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.list-item-left {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.auth-img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-left: 10px;
  border-radius: 50%;
  background-color:bisque;
}
.auth-img img {
  width: 100%;
  height: 100%;
}
.list-item-con {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fdfdfd;
}
.auth-info, .auth-info—2 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: 20px 0;
  margin-left: 10px;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: column;
}


.auth-info span:nth-child(1) {
  font-size: 16px;
  color: #363636;
}
.auth-info span:nth-child(2) {
  font-size: 12px;
  color: #696969;
}
.item-right {
  flex: 1;
  padding: 20px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.bot-text {
  width: 100%;
  height: 30px;
  padding-left: 20px;
  line-height: 30px;
}
</style>